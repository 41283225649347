.hotController {
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  display: block;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  /* opacity: 0.4; */
  /* background-color: red; */
}
.hotKey {
  position: relative;
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  color: #eee;
  text-align: center;
  pointer-events: all;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
}

.next {
  float: right;
  transform: rotate(180deg) !important;
}

.hotKey:focus {
  outline: none;
}