/******************** Bottom bar START *******************/
.rightbarContainer {
  position: fixed;
  /* border: solid 1px #fff; */
  /* border-bottom: none; */
  /* padding: 8px; */
  width: 100%;
  /* right: calc(50% - 35%); */
  /* bottom: -40px; */
  height: calc(100% - 35px);
  bottom: 0;
  /* border-top-left-radius: 40px; */
  /* border-top-right-radius: 40px; */
  transition: ease 0.5s all;
  /* height: 0; */
  z-index: 10;
}

.rightbar {
  height: 100%;
  font-size: 20px;
  background-color: #fff4;
  color: #000;
  padding: 20px;
}

.RightToggle {
  background: #000;
  opacity: 0.7;
  color: #eee !important;
}
.rotateRightToggle {
  /* transform: rotate(90deg) !important; */
}

.openRB {
   /* transform: translatey(100%); */
  /* display: none; */
  opacity: 0;
  height: 0;
  overflow: hidden;
}

#RightToggle {
  position: absolute;
  bottom: 20px;
  right: 20px;
  transform: rotate(270deg) !important;

}

/* content START */
#rightbar div {
  padding: 20px 30px;
  overflow: auto;
}

.rightbar h1 {
  font-size: 24px;
  text-transform: capitalize;
}
.rightbar h2 {
  font-size: 18px;
  text-transform: capitalize;
}
.rightbar p {
  margin: 15px 0;
  font-size: 17px;
}
.rightbar ol {
  padding-left: 30px;
}
.rightbar ol.alph {
  list-style-type: upper-alpha;
}

.rightbar li {
  margin-bottom: 15px;
  line-height: 19px;
  font-size: 16px;
}

.rightbar ul {
  list-style-type: disc;
  padding-left: 30px;
}

.rightbar b.red {
  color: var(--main-color);
}
/* content END */

/******************** Bottom bar END   *******************/

@media (max-width: 420px) {
  .rightbarContainer {
    /* width: 90%;
    right: calc(50% - 45% - 10px); */
  }
  #rightbar div {
    padding: 10px;
  }
}
