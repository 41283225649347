body {
  margin: 0;
  overflow: hidden;
  background: radial-gradient(circle, #eaeaea 0%, #777 100%);
}

.canvas {
  background: radial-gradient(circle, #eaeaea 0%, #777 100%);
}

canvas {
  display: block;
}

.icon {
  justify-content: right;
  z-index: 1000;
  padding: 10px;
  opacity: 0.7;
  display: inline-block;
  padding: 5px 10px;
}

.AudioPlay:hover,
.Fullscrean:hover {
  cursor: pointer;
  background-color: #fff1;
}

.enterFullScreen {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fffa;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreanmessage {
  padding: 30px;
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 30px;
}
