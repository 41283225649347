/******************* general  START *******************/
body {
  --main-color: #e31300;
  --secondary-color: #379b43;
  --temp-color: #196d98;

  --main-bg-color: #e31300;
  --secondary-bg-color: #379b43;

  --radius: 50%;
  --sidebar-width: 263px;
  --sidebar-top-position: 55px;
  --sidebar-top-position-phone: 500px;
  --scene-bg: #0c1a45;
  font-family: sans-serif;
}

ul {
  padding: 20px;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}


/******************** Toggle icon START *******************/
.Toggle {
  padding-right: 7px;
  padding-top: 2px;
  border: none;
  border-radius: 50%;
  color: #eee;
  transition: ease 0.6s all;
  width: 35px;
  height: 35px;
  text-align: center;
  font-weight: bolder;
  font-size: 19px;
  background-color:#000b;
  box-shadow: -2px 0px 10px #2222220d;
}
.toggleLogo {
  /* padding: 10px !important; */
  margin-top: 3px;
  margin-left: -2px;
}
.Toggle:focus {
  outline: none;
}

.Toggle:hover {
  /* color: #be2828; */
  cursor: pointer;
  /* background-color: #eee; */
  box-shadow: -2px 0px 10px #30060d00;
}

.openSB {
  transform: translateX(calc(var(--sidebar-width) * -1 - 4px));
}

