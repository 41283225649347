.appbar {
  background-color: #3334;
  position: fixed;
  top: 0;
  display: flex;
  /* height: 50px; */
  padding: 0px;
  width: 100%;
  align-items: center;
  justify-content: left;
  z-index: 10;
}

.navigation {
  padding-left: 10px;
  font-size: 13px;
  color: white;
  align-items: left;
  flex-grow: 1  ;
}

.navigationItem {
  /* text-decoration: underline; */
  margin: 0px 5px 2px 5px;
  cursor: pointer;
  background: #333;
  padding: 3px 10px;
  border: 1px solid #eee9;
  border-radius: 4px;
  transition: ease 0.3s all;
}

.navigation .last {
  text-decoration: none;
  color: #eee;
  cursor: inherit;
  background: #bdbdbd11;
  border: 1px solid #aaa;
  /* border: none; */
}

.right {
  padding-right: 10px;
  height: 35px;
}

.navSub {
  position: fixed;
  top: 26px;
  /* background: #3337; */
  width: 100%;
  left: 0;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 8px;
  /* transition: ease 0.2s all; */
  display: flex;
  justify-content: center;
}
.navSubItem {
  margin: 0px 2px;
  background: #0091EA;
  border: 1px solid #ccc;
  padding: 4px 14px;
  border-radius: 4px;
  color: #fff;
  font-size: 8px;
  /* transition: ease 0.7s all; */
}
.active {
  background-color:#0091EA ;;
}

.snav {
  flex-grow: 1;
}
